#{$project-name-space}login {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  &__header {
    color: #fff;
  }

  &__field {
    label {
      color: #fff !important;
    }
  }
}
