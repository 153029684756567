@import "./scss/variables";
@import "./pages/login/login";

.dimmer {
  height: 100vh !important;
}

#{$project-name-space}wrapper {
  -webkit-transform: translate3d(150px, 0, 0);
  transform: translate3d(150px, 0, 0);
  margin: 0 25px;
  max-width: calc(100% - 200px);
}
